import { useState } from "react";

const team = [
	{
		img: "/assets/images/tokens/6079.png",
		name: "Enrique",
		description:
			"He has been developing since the age of 11 and is in charge of all Staking/Solidity Contracts.",
	},
	{
		img: "/assets/images/tokens/9937.png",
		name: "Yelena",
		description:
			"Yelena is a talented Designer who can replicate any art style from just viewing it once!",
	},
	{
		img: "/assets/images/tokens/4753.png",
		name: "Sebastian",
		description:
			"Not only does Sebastian specialize in Web/UX Design, but he is an equally talented Web Developer.",
	},
	{
		img: "/assets/images/tokens/2986.png",
		name: "Daniel",
		description:
			"Without Daniel the community wouldnt be what it is today. Thank him for keeping you all updated!",
	},
];

const faq = [
	{
		question: "What chain is Space Cats on?",
		answer: "Space Cats is an NFT collection on Ethereum.",
	},
	{
		question: "What is $SPACE token?",
		answer: "$SPACE is a governance token with no monetary value. It is distributed daily to holders who have staked their Space Cat NFT. If you hold $SPACE, you can use it for voting and other decisions regarding Space Cats DAO.",
	},
	{
		question: "How do I stake my Space Cat NFT?",
		answer: "You can stake your Space Cat NFT by visiting the staking page. On this page, holders will be able to interact with the staking protocol. You can also stake your NFT through directly interacting with the smart contract.",
	},
	{
		question: "Will I be able to vote?",
		answer: "Voting rights are coming soon for Space Cats DAO. The community has full ownership of the Space Cats NFTS, not the team, and will make decisions for the entirety of the DAO."
	}
];

export default function HomePage() {
	const [expandedQuestion, setExpandedQuestion] = useState(null);

	return (
		<div
			style={{
				display: "flex",
				width: "100%",
				flexDirection: "column",
				alignItems: "center",
				gap: "5%",
			}}
		>
			<div
				className="column-text-container"
				style={{
					display: "flex",
					width: "70%",
					justifyContent: "center",
					gap: "15%",
					marginBottom: "5vw",
					alignItems: "center",
				}}
			>
				<div>
					<p
						data-aos="fade-right"
						style={{
							fontSize: "1.1em",
						}}
					>
						150 years have passed since the Cool Cats were born. After facing a threat of extinction, 9,999 of the bravest Space Cats donned their astronaut helmets and made their way to the far edges of the galaxy. With the help of aliens from a neighboring metaverse, they survived and became the Space Cats. Each Space Cat NFT will grant acess to "Space Cats DAO" and voting rights.
					
					</p>
				</div>

				<img
					data-aos="fade-left"
					style={{
						width: "22vw",
						height: "22vw",
						margin: 0,
						borderRadius: "5%",
						boxShadow: "0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01",
					}}
					src="/assets/images/preview.gif"
					alt="preview"
				/>
			</div>

			<div
				data-aos="zoom-out-up"
				data-aos-offset="240"
				data-aos-delay="100"
				style={{
					marginBottom: "5vw",
					fontSize: "1.2em",
				}}
			>
				<h1>What are Space Cats?</h1>
				<br></br>
				<p>
					Space Cats is a <b>DAO</b> that will purchase and fractionalize
					Cool Cats to be distributed to its members.
				</p>
				<p>
					The <b>$SPACE</b> token will represent fractional shares of Cool
					Cats held in the vault. Each Space Cat NFT earns 10 <b>$SPACE</b>{" "}
					per day from staking.
				</p>
				<p>
					In addition, each Space Cat NFT will grant access to the exclusive
					DAO community and voting rights over the <b>DAO's</b>{" "}
					assets.
				</p>
				<p>
					The Space Cat <b>DAO</b> will also provide holders with exclusive
					access to NFT drops, claimable NFTs, and future utility.{" "}
				</p>
			</div>

			<>
				<h1>Roadmap</h1>
				<br />
				<section
					id="timeline"
					style={{
						marginBottom: "5vw",
					}}
				>
					<article>
						<div
							className="inner"
							data-aos="fade-right"
							data-aos-offset="240"
						>
							<span className="date">
								<span className="year">2021</span>
							</span>
							<h2>Start</h2>
							<p>
								<ul>
									<li className="completed">NFT Launch</li>
									<li className="completed">Art Reveal</li>
									<li className="completed">Licensing</li>
								</ul>
							</p>
						</div>
					</article>
					<article>
						<div
							className="inner"
							data-aos="fade-left"
							data-aos-offset="240"
						>
							<span className="date">
								<span className="year">2022</span>
							</span>
							<h2>Next</h2>
							<p>
								<ul>
									<li className="completed">Website Revamp</li>
									<li>Staking</li>
									<li>$SPACE Token Launch</li>
								</ul>
							</p>
						</div>
					</article>
					<article>
						<div
							className="inner"
							data-aos="fade-right"
							data-aos-offset="240"
						>
							<span className="date">
								<span className="year">2023</span>
							</span>
							<h2>More to come</h2>
							<p>
								<ul>
									<li>Governance Voting Rights</li>
									<li>Space Cats DAO Development</li>
									<li>Space Cats Game</li>
								</ul>
							</p>
						</div>
					</article>
				</section>
			</>

			<div
				style={{
					marginBottom: "5vw",
				}}
			>
				<h1>The Team</h1>
				<br></br>
				<div>
					{team.map(({ img, name, description }, idx) => (
						<div
							key={idx}
							className="team-member"
							data-aos={
								idx % 2 === 0
									? "fade-down-left"
									: "fade-down-right"
							}
						>
							<img
								style={{
									width: "11vw",
									height: "11vw",
									margin: 0,
									borderRadius: "100%",
								}}
								src={img}
								alt="preview"
							/>
							<span
								style={{
									fontSize: "2vw",
								}}
							>
								{name}
							</span>
							<p
								style={{
									maxWidth: "60%",
								}}
							>
								{description}
							</p>
						</div>
					))}
				</div>
			</div>

			<div
				data-aos="zoom-out-up"
				style={{
					maxWidth: "50%",
				}}
			>
				<h1>Frequently Asked Questions</h1>
				<br></br>
				<div className="accordion">
					{faq.map(({ question, answer }, idx) => (
						<div key={idx} className="accordion-item">
							<button
								id="accordion-button-3"
								aria-expanded={idx === expandedQuestion}
								onClick={() => {
									if (idx === expandedQuestion) {
										setExpandedQuestion(null);
									} else {
										setExpandedQuestion(idx);
									}
								}}
							>
								<span className="accordion-title">
									{question}
								</span>
								<span
									className="icon"
									aria-hidden="true"
								></span>
							</button>
							<div className="accordion-content">
								<p>{answer}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
