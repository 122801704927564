import { Link } from "react-router-dom";
import blogPosts from "../BlogPosts";

export default function BlogPage() {
	return (
		<div
		 style={{
		 	display: "flex",
		 	flexDirection: "column",
			alignItems: "center",
		 	gap: "1vw",
		 }}
		>
			{blogPosts
					.sort((a, b) => b.date - a.date)
					.map((post, idx) => (
						<Link key={idx} to={`/blog/${idx}`} className="post-button">
							<h1>{post.title}</h1>
							<span>{post.date.toDateString()}</span>
							<br></br>

							<div
								style={{
									overflowY: "auto",
									maxHeight: "12vh"
								}}
							>
								<p>{post.description}</p>
							</div>
						</Link>
					))}
		</div>
	);
}


