import { Link, Outlet, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import bannedCountries from './bannedCountries'
const pages = [
	{
		to: "/",
		text: "Home",
	},
	{
		to: "/staking",
		text: "Staking",
		onClick(e) {
			e.preventDefault();
			Swal.fire({
				title: "Coming q1 2023",
				icon: "info",
			});
			const country = localStorage.getItem("country");
			if (bannedCountries.indexOf(country) >= 0) {
				e.preventDefault();
				Swal.fire({
					title: "Staking is not available in your country!",
					icon: "info",
				});
			}
			
		},
	},
	{
		to: "/voting",
		text: "Voting",
		onClick(e) {
			e.preventDefault();
			Swal.fire({
				title: "Coming Soon",
				icon: "info",
			});
		},
	},
	{
		to: "/blog",
		text: "Blog",
	},
];

function App() {
	const location = useLocation();
	return (
		<div
			style={{
				background: "rgb(15, 22, 31, 0.6)",
				textAlign: "center",
				display: "flex",
				position: "relative",
				width: "100%",
				minHeight: "100vh",
			}}
		>
			<div
				style={{
					margin: "auto",
				}}
			>
				<img
					style={{
						width: location.pathname === "/" ? "25.5%" : "50%",
					}}
					src="/assets/images/logo.png"
					alt="logo"
				></img>

				<div
					style={{
						fontFamily: "SpaceCats",
						fontSize: "3em",
						// marginTop: "1em",
					}}
				>
					{pages.map((page, idx) => (
						<Link
							key={idx}
							to={page.to}
							className="m-5 route"
							onClick={page.onClick}
							style={
								location.pathname === page.to
									? {
											textDecoration: "underline",
									  }
									: {}
							}
						>
							{page.text}
						</Link>
					))}
				</div>

				<div
					style={{
						minHeight: "55vh",
						textAlign: "center",
					}}
				>
					<Outlet />
				</div>

				<footer>
					<div style={{ marginTop: "20px" }} id="socials">
						<a
							target="_blank"
							href="https://twitter.com/SpaceCatsDAO"
							rel="noreferrer"
						>
							<img
								style={{ width: "60px", marginRight: "40px" }}
								src="/assets/images/socials/twitter.png"
								alt="twitter"
							></img>
						</a>
						<a
							target="_blank"
							href="https://etherscan.io/address/0x333761cefcc5c30786fb8af304569c8d171fee9e"
							rel="noreferrer"
						>
							<img
								style={{ width: "60px", marginRight: "40px" }}
								src="/assets/images/socials/etherscan.webp"
								alt="etherscan"
							></img>
						</a>

						<a
							target="_blank"
							href="https://opensea.io/collection/thespacecatsnft"
							rel="noreferrer"
						>
							<img
								style={{ width: "60px" }}
								src="/assets/images/socials/opensea.png"
								alt="opensea"
							></img>
						</a>
					</div>
				</footer>
			</div>
		</div>
	);
}

export default App;
