import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import store from "./GlobalStore";

import { BrowserRouter, Routes, Route } from "react-router-dom";
//import StakePage from "./components/StakePage";
import HomePage from "./components/HomePage";
import BlogPage from "./components/BlogPage";
import BlogPostPage from "./components/BlogPostPage";
import VotingPage from "./components/VotingPage";
import Particles from "./components/Particles";
import AOS from "aos";
import "./css/bootstrap.css";
import "./css/index.css";
import "aos/dist/aos.css";

import bannedCountries from './bannedCountries';
import Swal from "sweetalert2";

AOS.init();

if (window.location.hostname.indexOf("spacecatsnft.io") >= 0) {
	Swal.fire({
	  title: 'We have a new twitter!',
	  icon: 'info',
	  html:
		`We were locked out of our old twitter, so for future updates please check <a style='color:#545454' target="_blank" href='https://twitter.com/SpaceCatsDAO'><img style='width:20px' src='assets/images/socials/twitter.png'></img>@SpaceCatsDAO</a>`,
	  showCloseButton: true,
	  showCancelButton: true,
	  focusConfirm: false,
	  confirmButtonText: 'Take Me There!',
	  cancelButtonText: 'Cancel',
	}).then(result => {
		if (result.isDismissed) {
			window.location.href = "https://spacecatsdao.com"
		} else if (result.isConfirmed) {
			window.location.href = "https://twitter.com/SpaceCatsDAO"
		}
	})
}
async function getCountry() {
	const localCountry = localStorage.getItem("country");
	if (localCountry) {
		return localCountry;
	}
	
	if (window.location.href.indexOf("space")) {
		//figure out later
	} else {
		const res = await fetch("http://localhost:3535");
		const countryCode = await res.text();
		return countryCode;
	}
}

async function handleCountry() {
	const country = await getCountry();
	if (window.location.pathname == "/staking" && bannedCountries.indexOf(country) >= 0) {
		Swal.fire({
			title: "Staking is not available in your country!",
			icon: "info",
			allowEscapeKey: false,
			allowOutsideClick: false,
			allowEnterKey: false,
			
		}).then(() => {
			
			window.location.href ="/";
		});
	}
}
//handleCountry();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<>
		<Particles />
		<Provider store={store}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<App />}>
						<Route index element={<HomePage />} />
						{/* <Route path="staking" element={<StakePage />} /> */ }
						<Route path="blog" element={<BlogPage />} />
						{/* <Route path="voting" element={<VotingPage />} /> */}
						<Route path="blog/:id" element={<BlogPostPage />} />
						<Route
							path="*"
							element={
								<>
									<h1>404</h1> <h1>Page Not Found</h1>
								</>
							}
						/>
					</Route>
				</Routes>
			</BrowserRouter>
		</Provider>
	</>
);
