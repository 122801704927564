const blogPosts = [
	{
		title: "We have sold out!",
		date: new Date("Oct 15 2021"),
		description:
			"Click here to read the post",
		content: () => (
			<>
				<p>
					GM Space Cats community, and welcome to the first installment of our updates blog! It's October 2021, and as you know, the Space Cats collection has recently minted out. Exciting day for the Space Cats community! We're going to be providing blog updates every few months as a sort of summary showing our progress on the roadmap. 
					
					So, what has been done so far? We are planning to revamp our website and add more sections within the coming months, so stay tuned for that! The entire collection has also minted out, with the 9999 supply of Space Cats NFTs belonging to our amazing community. The DAO treasury purchases will most likely be postponed until the market settles down, but rest assured, the DAO treasury will acquire NFTs according to the governance vote sometime in the future. We have also revealed the artwork for all 9,999 Space Cats! The team hopes you enjoy the art, thanks to our talented designer. 
					
					The Space Cats website will be our primary and official means of communication. If there are any important updates, they will be posted on our website first. 
					
					Please keep an eye on our blog for the latest updates and announcements!
				</p>
			</>
		),
	},
	{
		title: "What we have been working on",
		date: new Date("December 3 2021"),
		description:
			"Click here to read the post",
		content: () => (
			<>
				<p>
					GM Space Cats community! The team is back with another blog post, this time to update holders on what progress we've been making in the background. As a precaution, please do not trust any updates that are not from the official Twitter or website. We have received many reports from the community asking us if another page is official, or if a minting site is real. These types of scams are very common in the NFT community, and we are trying our hardest to stop them, but there are always going to be scammers trying to deceive the NFT community, especially in a collection like ours. If you have received a suspicious DM regarding Space Cats, or stumble upon some sort of website about Space Cats that is not our official one, please report it to us! 

					Space Cats has been flourishing over the past two months, and the team has been working hard in the meantime. Here's some of the latest updates:

					The team has been working on developing the next phase of Space Cats: which includes staking and voting rights/governance. The development of both of these will likely take a long time, so we will update the community on our progress throughout the next months/year. 
				</p>
				<br></br>
				<p>
					Our vision for the development of staking includes NFT holders staking their NFT in a decentralized way, with stakers then receiving governance tokens (that hold no value), depending on how long they choose to stake their NFT for. The only issue with this is the legality surrounding governance tokens, which will likely be an issue that other teams face by distributing tokens to holders. Therefore, the team will decide the best option later on once the staking protocol is actually finished.

					As a sidenote, we are looking for more talented people to join the Space Cats team. If you'd like to join the Space Cats team, send us an email at team@spacecatsdao.com! 

					Recruitment for NFT projects isn't always the easiest, so we wanted to make that public. If you are interested in working in web3, then feel free to reach out!

					Please keep an eye on our blog for the latest updates and announcements!
				</p>
			</>
		),
	},
	{
		title: "Staking Protocol Progress",
		date: new Date("Febuary 15 2022"),
		description:
			"Click here to read the post",
		content: () => (
			<>
				<p>
					GM Space Cats community! Today, we have some more updates available for everyone to view. The team has been hard at work over the last few months, working on developing mainly the staking protocol for Space Cats. We don't have an estimated time of completion for this, since we have been having difficulties getting it developed and tested. We wanted to be fully transparent about this and hope the community understands. 

					With the state of the current market, it's been tough finding developers to assist us with Space Cats. Thus, development of the staking protocol will take longer than expected.

					We wanted to provide another update on how the team is doing so the community knows. To clarify, we are still here, building in this down-trending market. Unfortunately, Bitcoin is down over 30% from our last update, but we are still here, working on developing the future of Space Cats. 

					Please keep an eye on our blog for the latest updates and announcements!
				</p>
			</>
		),
	},
	{
		title: "Staking Protocol Testnet Success!",
		date: new Date("April 17 2022"),
		description:
			"Click here to read the post",
		content: () => (
			<>
				<p>
					GM Space Cats community! Today, we have another blog post with some updates for the community. Since our last update, we are still working on developing the staking protocol for Space Cats. We've also been working on some small stuff in the background, including another revision of our staking protocol. We realized the last one had an inefficient contract when we were testing, so we have another developer rewriting the contract and fixing any issues we found in the previous staking protocol. 
					
					We are also working on a website revamp that will be coming in the future. We have contacted multiple website designers to assist us with developing our new website. This website will host all the new updates coming to Space Cats, including governance and the staking protocol. Users can interact directly with the contract and voting through the website by connecting their wallet. We are unsure when these updates will be completely finished. As we said in a previous update, we are still looking for developers and other team members to help us push out these updates more quickly. Our estimate is that staking will be done sometime later this year, but this is not a guarantee.
					
					Please keep an eye on our blog for the latest updates and announcements!
				</p>
			</>
		),
	},
	{
		title: "Answering your questions",
		date: new Date("June 27 2022"),
		description:
			"Click here to read the post",
		content: () => (
			<>
				<p>
					GM Space Cats community!

					Some people reached out to us inquiring what the DAO funds are being used for. The same amount we promised in the beginning will be allocated towards the DAO. However, we are currently not holding anything in the DAO besides the initial ETH that funded it, since we prepared for the market downturn. We are currently not holding any NFTs, including Cool Cats because of the recent market downturn and volatility. Once the market cools down, we are planning to hold a vote that the Space Cats community can participate in. They will be able to decide what the funds are used for. This update is to increase transparency for the Space Cats community as we have received some questions about what exactly the DAO is for.

					We are also continuing to work on the Space Cats staking protocol. We will provide more updates on our next blog post about the staking protocol, as all details for it have not yet been finalized. 

					Please keep an eye on our blog for the latest updates and announcements!
				</p>
			</>
		),
	},
	{
		title: "More Staking Updates",
		date: new Date("August 14 2022"),
		description:
			"Click here to read the post",
		content: () => (
			<>
				<p>
					We have some good news for everyone. Staking is on the way, and our plan is to release it later in the year (most likely October or November). We have done many tests on the contract and we are currently working with developers to make it efficient to use. Since we created this contract ourselves, it is taking longer than we expected, but we are glad to have an update on the development of the staking protocol for everyone. 
					
					As we said in the roadmap under the "Next" section, we are going to complete the Space Cats website revamp and staking protocol next. We have developers working on these next updates and should have some good news within the next few months on their development.
					
					Thanks to the community for staying strong through the bear market. The Space Cats team is still here, going strong, and we plan to have some good updates for the community soon!
					
					Please keep an eye on our blog for the latest updates and announcements!
				</p>
			</>
		),
	},
	{
		title: "Clarifying Licensing",
		date: new Date("October 6 2022"),
		description:
			"Click here to read the post",
		content: () => (
			<>
				<p>
					GM Space Cats community! We have been making great progress since the last update. It's finally October, and we are on track to complete multiple parts of our roadmap. To clarify, we have finished the "Start" of our roadmap completely. According to our website, we had three main points for the start of the roadmap: NFT Launch, Art Reveal, and Licensing. We have finished all three of these, with the art being revealed for all 9,999 Space Cats NFTs, and licensing has been changed to CC0. 

					You might be wondering, what does CC0 mean? We've addressed this on our Twitter multiple times. In summary, the licensing of the Space Cats art is in the public domain. This means that the Space Cats team/artists do not own the artwork anymore. It is free for anyone to reuse and create derivatives for. We believe this is the best choice for the Space Cats community, as we do not receive royalties from any NFT sale and we want the community to benefit fully from the artwork. 

					We have also completed our website revamp, which was the next part of our roadmap. Thanks to our website designer for finishing this! If you have not seen it yet, go check out the website! The link is https://spacecatsdao.com. This is our new official website and we will provide all updates through this website.

					Please keep an eye on our blog for the latest updates and announcements!
				</p>
			</>
		),
	},
	{
		title: "Staking ready for production!",
		date: new Date("October 18 2022"),
		description:
			"Click here to read the post",
		content: () => (
			<>
				<p>
					GM Space Cats community! It's still October, but we have more updates for the community. 
					
					Staking should be released sometime this month! We have completed the development of the staking protocol, including testing the contract. The Space Cats staking protocol, barring any unforeseen circumstances, should be out this month for our users.
					
					Recently, Yuga Labs has been under investigation for distributing the Apecoin token to holders. We will still be distributing the Space Cats governance token (that holds no value) through staking, and users from the following countries will be restricted: Afghanistan, Angola, Azerbaijan, Bahamas, Bosnia and Herzegovina, Botswana, Burundi, Cambodia, Cameroon, Canada, Chad, China, Congo, Congo (Democratic Republic), Cuba, Eritrea, Ethiopia, Ghana, Guinea, Guinea-Bissau, Haiti, Iran, Iraq, Japan, Lao People’s Democratic Republic, Libya, Madagascar, Mozambique, Nicaragua, North Korea, Pakistan, Serbia, Seychelles, Somalia, South Korea, South Sudan, Sri Lanka, Sudan, Syrian Arab Republic, Tajikistan, Trinidad and Tobago, Tunisia, Turkmenistan, Uganda, Ukraine, United States, Uzbekistan, Vanuatu, Venezuela, Virgin Islands (British), Virgin Islands (US), Yemen, and Zimbabwe.
					
					Please keep an eye on our blog for the latest updates and announcements!
				</p>
			</>
		),
	}




];

export default blogPosts;
