import { useParams } from "react-router-dom";
import blogPosts from "../BlogPosts";

export default function BlogPostPage() {
	const { id } = useParams();
	const post = blogPosts[Number(id)];

	return (
		<div
			style={{
				padding: "15px",
			}}
		>
			<h1>{post.title}</h1>
			<p>Posted on {post.date.toDateString()}</p>
			<br></br>
			<div
				style={{
					fontSize: "1.2em",
					maxWidth: "60%",
					margin: "auto",
				}}
			>
				<post.content />
			</div>
		</div>
	);
}
