import { ethers } from "ethers";
const initialState = {
	ownedTokens: [],
	stakedTokens: [],
	connected: false,
	owedRewards: ethers.BigNumber.from(0),
	tokenBalance: ethers.BigNumber.from(0),
	isApproved: false,
};

// Use the initialState as a default value
export default function appReducer(state = initialState, action) {
	// The reducer normally looks at the action type field to decide what happens
	switch (action.type) {
		// Do something here based on the different types of actions
		case "connected": {
			return {
				...state,
				connected: action.payload,
			};
		}

		case "tokenBalance": {
			return {
				...state,
				tokenBalance: action.payload,
			};
		}

		case "ownedTokens": {
			return {
				...state,
				ownedTokens: action.payload,
			};
		}

		case "owedRewards": {
			return {
				...state,
				owedRewards: action.payload,
			};
		}

		case "stakedTokens": {
			return {
				...state,
				stakedTokens: action.payload,
			};
		}

		case "isApproved": {
			console.log(action);
			return {
				...state,
				isApproved: action.payload,
			};
		}
		default:
			// If this reducer doesn't recognize the action type, or doesn't
			// care about this specific action, return the existing state unchanged
			return state;
	}
}
